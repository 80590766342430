import React, { Component } from 'react';
import { node } from 'prop-types';
import styled from 'styled-components';

const TitleContainer = styled.div`
  padding: ${({ noTopMargin }) => (noTopMargin ? 0 : `2rem`)} 0 2rem 0;

  ${({ theme }) => theme.onMobile} {
    padding-bottom: 1rem;
  }
`;

const Title = styled.h1`
  display: inline;
  background-image: linear-gradient(
    to bottom,
    transparent 70%,
    ${({ theme }) => theme.lightGrey} 70%
  );
  color: ${({ theme }) => theme.primary};
  margin: 0;
`;

class PageTitle extends Component {
  static propTypes = {
    children: node.isRequired,
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <TitleContainer {...rest}>
        <Title>{children}</Title>
      </TitleContainer>
    );
  }
}

export default PageTitle;
