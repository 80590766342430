import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import PageTitle from 'components/PageTitle';
import PageHelmet from 'components/Helmets/PageHelmet';
import { Post } from 'style/components/blog-list';
import { TextColumn } from 'style/components';

export default class BlogListPage extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <section>
        <PageHelmet pageTitle="Blog posts" path="/blog" />
        <TextColumn style={{ paddingBottom: `2rem` }}>
          <PageTitle>Latest Posts</PageTitle>

          {posts.map(({ node: post }) => (
            <Post key={post.id}>
              <p>
                <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
                <span> &bull; </span>
                <small>{post.frontmatter.date}</small>
              </p>
              <p>
                {post.excerpt}
                <br />
                <br />
                <Link to={post.fields.slug}>Keep Reading →</Link>
              </p>
            </Post>
          ))}
        </TextColumn>
      </section>
    );
  }
}

BlogListPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query BlogListQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
