import styled from 'styled-components';

export const Post = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 0.5rem;
  box-shadow: 0 2rem 4rem -0.2rem rgba(0, 0, 0, 0.06);
  margin: 0 auto 2rem auto;
  padding: 1.5rem 2rem;

  ${({ theme }) => theme.onMobile} {
    padding: 0.75rem 1rem;
  }
`;
